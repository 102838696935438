/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/
@import "sass-lib";

@media all and (max-width: 1280px) {


/* global
----------------------------------------------------------------------------------------------*/

.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.flex-list {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-flex-flow: row wrap;}
.wrapper {width: 960px;}


/* structure 
----------------------------------------------------------------------------------------------*/



/* header
----------------------------------------------------------------------------------------------*/
header {
    height: 60px;
    .wrapper {width: auto; margin: 0 30px;}
    .logo {width: 80px;}
    .lang {
        span {
            padding: 20px 17px 20px 0; font-size: 14px;
            &:after{ top:-5px; }
        }
        .opt_lang {
            padding: 5px 15px;
            a {font-size: 13px;}
        }
    }
    .nav_menu {
        ul {
            li {
                a {font-size: 16px; padding: 20px 17px;}
                ul {
                    li {
                        a {font-size: 15px;}
                    }
                }
            }
        }
    }
}

/* middle
----------------------------------------------------------------------------------------------*/
.hdline_campaign {
    .big {
        .v-center {top: unset; @include transform(translateY(0)); }
    }
}
.middle {padding-top: 60px;}

.txt_top {
    h2 {font-size: 40px;}
    h6{ margin-top: 10px; }
}
.SlideProd {
    background-size: 100%; background-position: 50% 200px;
    .SlideImg {
        .slick-list {padding: 50px !important;}
        .img {
            &.slick-center {
                @include transform(scale(1.35));
            }
        }
    }
    .slideKet_img {
        width: 350px;
        .dec {
            h3 {font-size: 26px;}
        }
    }
}
.home_article {
    .listing {
        .item {
            h5 {font-size: 36px;}
        }
    }
}
.list3box {
    .box {
        width: 240px;
        .txt_abs {
            h3 {font-size: 22px; line-height: 28px;}
        }
    }
}


.wrap_sec {width: 840px;}
.wrap_pngalaman, .wrap_campaign {padding: 80px 0 0;}
.wrap_event {padding: 80px 0;}
.SlideProd {
    .wrap_slide {width: 880px;}
}

.wrap_campaign {
    .wrapper {width: 960px;}
}
.wrp_detail {width: 960px;}
.hdline_campaign {
    .big {
        .dec {
            padding: 35px 50px 35px 35px;
            h3 {font-size: 26px; line-height: 30px;}
            p {font-size: 16px;}
        }
    }
}

.list_campaign {
    .box {
        .dec {
            padding: 15px 20px 40px;
            h3 {font-size: 16px; line-height: 20px;}
            .tgl {font-size: 16px; position: absolute; bottom: 20px;}
        }
    }
}
.sec_find {
    width: 840px;
    > h3{font-size: 28px;}
}
.list_find {
    .item {max-width: 40%;}
} 

.list_article {
    .item {
        .det {
            h3 {font-size: 26px; line-height: 30px;}
            p {max-height: 96px;overflow: hidden; text-overflow: ellipsis; display: -webkit-inline-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical;}
        }
    }
}
.main_event {
    .asside_article {
        .boxs {
            padding: 50px 25px;
            h2 {font-size: 38px; line-height: 40px;}
        }
    }
}
.main_article .filter .col select {font-size: 16px;}
.pagging {
    ul {
        li {
            a {width: 30px; height: 30px; line-height: 30px; font-size: 16px;}
        }
    }
}

.text_detail h1 {font-size: 36px; line-height: 42px;}
.related h3 {font-size: 24px;}


.page_screen_age {
    overflow: hidden; min-height: unset;
    .drop_content {
        h6 {font-size: 20px; margin-bottom: 15px;}
        h2 {font-size: 40px; margin-bottom: 20px;}
    }
    .drop_content {
        form .row .col {margin: 0 24px;}
        .link {
            .btn {font-size: 16px; line-height: 46px; min-width: 250px;}
            > span {font-size: 14px;}
        }
        form .row{ margin-bottom: 20px; }
    }
    .content_pg_Age{
        .logo_bir{ margin: 0 auto 20px; }
        .nav_lang{ margin-bottom: 20px; }
    }
}

/* footer
----------------------------------------------------------------------------------------------*/



} /* end media*/





/* ----------------------------   Media 768   ------------------------------------------------*/



@media all and (max-width: 1023px) {


/* global
----------------------------------------------------------------------------------------------*/


.after_clear:after,.wrapper:after,section:after,.container:after{clear:both;display: block;content:" "}
.before_clear:before{clear:both;display: block;content:" "}


/* structure 
----------------------------------------------------------------------------------------------*/

.wrapper {width: 720px;}


/* header
----------------------------------------------------------------------------------------------*/

header {
    .logo {z-index: 5;}
    .burger {
        display: block; width: 30px; height: 19px;  float: left; position: relative; margin: 2px 0 0 25px; cursor: pointer; margin-top: 20px;
        span {
            height: 3px; background:#fff; display: block; position: absolute; left: 0; right: 0; @include transition(all ease-out 0.25s); @include borderRadius(1.5px);
            &:nth-of-type(1) {top: 0;}
            &:nth-of-type(2),
            &:nth-of-type(3) {top: 8px;}
            &:nth-of-type(4) {top: 16px;}
        }
        &.act {
            span {
                @include transition(all ease-out 0.25s);
                &:nth-of-type(1) {width: 0%; left: 50%; top: 8px;}
                &:nth-of-type(2) {@include transform(rotate(45deg));}
                &:nth-of-type(3) {@include transform(rotate(-45deg));}
                &:nth-of-type(4) {top: 8px;left: 50%; top: 8px; width: 0%;}
            }
        }
    }
    .lang {
        float: left; border-left: none;
        .opt_lang {z-index: 99;}
    }
    .nav_menu {
        display: none; position: fixed; left: 0; right: 0; top: 60px; background: #e1232f;z-index: 4;
        ul {
            padding-top: 40px; border-top: 2px solid #d01a21;
            li {
                float: none; display: block;
                a {
                    position: relative; padding: 15px 25px;
                    &:before {border: none;}
                    &.act {
                        &:before {height: 100%;}
                    }
                }
                &:hover {
                    > a {
                        &:before {height: 0;}
                        &.active,
                        &.act {
                            &:before {height: 100%;}
                        }
                    }
                }
                
                ul {
                    position: relative; width: 100%; display: none; padding: 5px 45px 10px;
                }
            }
        }
    }
}


/* middle
----------------------------------------------------------------------------------------------*/

.list3box {
    top: -55px;
    .box {
        width: 190px;
        .txt_abs h3 {font-size: 18px; line-height: 22px;}
    }
}

.txt_top {
    margin: 0 auto;
    h6 {margin-bottom: 10px;}
    h2 {font-size: 34px; margin: 0;}
}

.top_back {
    .breadcrumb {margin-bottom: 10px;}
    &.float {
        .breadcrumb {float: none; display: block;}
        .navSlider {
            float: none; margin-top: 20px;  display: block;
        } 
    }
    .navSlider{
        .nav{ 
            margin-bottom: 20px; border-left: none; border-right: 1px solid #363636; 
            &:last-child{ border-right:none; }
        }
    }
} 

.text_detail h1 {font-size: 30px; line-height: 36px;}
.related {
    h3 {font-size: 20px;}
    .list_related .item h4 {font-size: 16px; line-height: 20px;}
}
.text_detail .short:before {left: -10px;}
.main_event {
    .asside_article {
        margin-top: 105px;
    }
}
.detail {
    .main_event {
        .asside_article {
            margin-top: 38px;
        }
    }
}

.SlideProd {
    background-position: 50% 155px;
    .SlideImg {
        .img.slick-center {@include transform(scale(1.5));}
    }
    .logo_prod img {width: 250px;}
    .slideKet_img .dec h3 {font-size: 24px;}
    .pg_slider {padding-top: 200px;}
    .wrap_slide {width: 700px;}
    .slick-arrow {
        right: 15px;
        &.slick-prev {left: 15px;}
    }

}
.home_article .listing .item h5 {font-size: 28px;}


.wrap_sec {width: 720px;}
.sec_experience .txt_top {width: 600px;}

.slideLabel {
    .slideImg_logo {
        width: 600px;
        .logoBintang {width: 600px;}
    }
    .dotSlide {margin: 0 50px;}
}

.wrap_campaign .wrapper {width: 720px;}
.list_campaign .box .dec h3 {font-size: 14px;}
.hdline_campaign .big .dec {
    padding: 20px 35px 20px 30px;
    h3 {font-size: 22px; line-height: 26px;}
    p {line-height: 24px; max-height: 94px;overflow: hidden; text-overflow: ellipsis; display: -webkit-inline-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical;}
}
.wrp_detail {
    width: 720px;
    .txt_top {width: 640px;}
    .list_campaign .box .dec h3 {font-size: 14px;}
}

.list_article {
    display: block;
    .item {
        display: block;
        .img {
            width: 100%; margin-bottom: 20px;
            a {display: block; height: auto; overflow: hidden; position: relative;}
            img {width: 100%; height: 200px; object-fit: cover;}
        }
        .det {
            width: 100%;
            h3 {font-size: 22px; line-height: 26px; margin-bottom: 15px;}
        }
    }
}

.main_event .asside_article .boxs {
    padding: 30px 20px;
    h2 {font-size: 30px; line-height: 34px;}
}
.main_article h3.title {font-size: 20px; margin-bottom: 30px;}
.main_article .filter .col {
    &:last-child{margin-right: 0;}
    label {font-size: 14px;}
}

.sec_find {width: 720px;}


.page_screen_age {
    @include boxSizing(border-box); min-height: unset; overflow: hidden;
    .content_pg_Age {
        width: 640px; top: 45%;
        .nav_lang {
            padding-left: 35px;
            a {font-size: 16px;}
        }
    }
}

/* footer
----------------------------------------------------------------------------------------------*/
footer {
   
}






} /* end media*/
@media all and (max-width: 1200px) {
    .SlideProd {
        .slick-arrow {
            bottom: unset; top: 260px;
        }
    }
}